<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <div class="table-page-search-wrapper-top">
          <a-row :gutter="16">
            <a-col :md="9" :sm="24">
              <a-checkbox @change="onChangeResign">
                显示离职员工
              </a-checkbox>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-button style="margin-right:20px" @click="find('last')">上个月</a-button>  
              <a-month-picker @change="onDateChange" v-model="dateString" />
              <a-button style="margin-left:20px" @click="find('next')">下个月</a-button>    
            </a-col>
          </a-row>
        </div>
      </div>
      <my-alert :totalCount="totalCount" />
      <s-table
        ref="table"
        size="default"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data="loadData"
        :alert="false"
        showPagination="auto"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="status" slot-scope="text">
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </span>
        <span slot="jobStatus" slot-scope="text">
          <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">查看</a>
          </template>
        </span>
      </s-table>
      <!-- <step-by-step-modal ref="modal" @ok="handleOk" /> -->

      <!-- evidence info -->
      <a-drawer
        placement="right"
        width="600px"
        class="myDescription"
        :visible="visible"
        :after-visible-change="afterVisibleChange"
        @close="onClose"
      >
        <a-card :bordered="false" v-if="drawer">
          <a-descriptions title="基本信息" class="evidence">
            <a-descriptions-item label="姓名" span="3">{{ 
              drawer.name ? drawer.name : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="接访次数" span="3">{{
              drawer.visitNum 
            }}</a-descriptions-item>
            <a-descriptions-item label="认购笔数" span="3">{{
              drawer.subNum
            }}</a-descriptions-item>
            <a-descriptions-item label="签约笔数" span="3">{{
              drawer.signNum
            }}</a-descriptions-item>
            <a-descriptions-item label="在职状态" span="3">{{
              drawer.jobStatus ? drawer.jobStatus ? '在职' : '离职' : '-' 
            }}</a-descriptions-item>
          </a-descriptions>
        </a-card>
      </a-drawer>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import moment from 'moment'
import { STable, Ellipsis, Media, MyAlert } from '@/components'
import StepByStepModal from './modules/StepByStepModal'
import CreateForm from './modules/CreateReport'
import { statistics } from '@/api/consultant'
const columns = [
  {
    title: '姓名',
    dataIndex: 'name'
  },
  {
    title: '接访次数',
    dataIndex: 'visitNum',
    align: 'center',
    sorter: true
  },
  {
    title: '认购笔数',
    dataIndex: 'subNum',
    align: 'center',
    sorter: true
  },
  {
    title: '签约笔数',
    dataIndex: 'signNum',
    align: 'center',
    sorter: true
  },

  {
    title: '状态',
    dataIndex: 'jobStatus',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

const statusMap = {
  false: {
    status: 'default',
    text: '离职'
  },
  true: {
    status: 'processing',
    text: '在职'
  }
}

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis,
    CreateForm,
    StepByStepModal,
    Media,
    MyAlert
  },
  data() {
    this.columns = columns
    return {
      // create model
      requestParameters: {},
      relevance: null,
      visible: false,
      confirmLoading: false,
      mdl: null,
      // 高级搜索 展开/关闭
      advanced: false,
      search: {
        visible: false
      },
      drawer: [],
      jobStatus: false,
        // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        let sorterStr = ''
        let sortType = ''
        if (parameter.sortOrder === 'descend') {
          sortType = 'desc'
          sorterStr = `${parameter.sortField}`
        } else if (parameter.sortOrder === 'ascend') {
          sorterStr = `${parameter.sortField}`
          sortType = 'asc'
        } else {
          sorterStr = 'visitNum'
          sortType = 'desc'
        }
        const requestParameters = {
          page: parameter.pageNo - 1,
          pageSize: parameter.pageSize,
          sortField: sorterStr,
          sortType: sortType,
          houseId: this.houseId,
          yearMonth: this.dateString.replace(/-/, ''),
          jobStatus: 1
        }
        if (this.jobStatus) {
          delete requestParameters.jobStatus
        }
        return statistics(requestParameters).then(res => {
          const result = this.resFormat(res)
          this.totalCount = result.totalCount
          return result
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      dateString: null,
      totalCount: 0
    }
  },
  filters: {
    statusFilter (type) {
      return statusMap[type].text
    },
    statusTypeFilter (type) {
      return statusMap[type].status
    }
  },
  created() {
    this.getPresentDate()
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    userId() {
      return this.$store.state.user.info.id
    },
    houseId() {
      return this.$store.state.house.houseId
    }
  },
  methods: {
    moment,
    getPresentDate() {
    const newDate = new Date()
      let month = newDate.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      this.dateString = newDate.getFullYear() + month
    },
    handleEdit (record) {
      this.drawer = record
      // this.report.visible = true
      this.visible = true
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    // report info
    onClose () {
      this.visible = false

    },
    afterVisibleChange (val) {
    },
    findByKeyword () {
      this.search.visible = true
      this.$refs.table.refresh()
    },
     filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    onChangeResign(e) {
       console.log(`checked = ${e.target.checked}`);
       this.jobStatus = e.target.checked
       this.findByKeyword()
    },
    onDateChange(date, dateString) {
      console.log(date, dateString);
      this.dateString = dateString
    },
    find(type) {
      if (!this.dateString) return
        let year = this.dateString.slice(0, 4)
        let month = this.dateString.slice(-2)  
      if (type === 'last') {
        if (month < 2) {
          year = +year - 1
          month = '12'
        } else {
          month = +month - 1
        }
      } else {
        if (month > 11) {
          year = +year + 1 
          month = '1'
        } else {
          month = +month + 1
        }
      }
        if (month < 10) {
          month = '0' + month
          }
        this.dateString = year + '-' + month
        this.findByKeyword()
    }
}
}
</script>

<style lang="less" scoped>
.log {
  /deep/ .ant-descriptions-item-content {
    span:first-child {
      font-weight: 700;
    }
    span:last-child {
      font-weight: 700;
      margin-left: 50px;
    }
  }
  /deep/ .ant-descriptions-item-label {
    font-weight: 700;
  }
}
.evidence {
  /deep/ .ant-card {
    width: 120px;
    display: inline-block;
    vertical-align: text-top;
    margin-right: 20px;
    margin-top: 20px;
  }
}
/deep/ .table-page-search-wrapper-top{
  padding-bottom:20px;
}
</style>
