import request from '@/utils/request'

const consultantApi = {
    base: '/api/v1/rest/consultants',
    statistics: '/api/v1/consultant/statistics',
    query: '/api/v1/consultant/query'
}

export function consultants(param) {
    let url = consultantApi.base
    if (param) {
        url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
    }
    return request({
        url,
        method: 'get'
    })
}

/**
 * statistics
 * @param params { houseId: '', jobStatus: '', page: '', pageSize: '', yearMonth: '202001' }
 * @returns {*}
 */
export function statistics(params) {
    let url = consultantApi.statistics
    if (params) {
        url += '?' + Object.keys(params).map(item => `${item}=${params[item]}`).join('&')
    }
    return request({
        url,
        method: 'get'
    })
}

export function queryConsultant(param) {
    let url = consultantApi.query
    if (param) {
        url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
    }
    return request({
        url,
        method: 'get'
    })
}

export function addConsultant(data) {
    return request({
        url: consultantApi.base,
        method: 'post',
        data
    })
}

export function getConsultant(id) {
    return request({
        url: consultantApi.base + `/${id}`,
        method: 'get'
    })
}

export function patchConsultant(id, data) {
    return request({
        url: consultantApi.base + `/${id}`,
        method: 'patch',
        data
    })
}

export function putConsultant(id, data) {
    return request({
        url: consultantApi.base + `/${id}`,
        method: 'put',
        data
    })
}

export function delConsultant(id) {
    return request({
        url: consultantApi.base + `/${id}`,
        method: 'delete'
    })
}
